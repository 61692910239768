<template>
  <div class="background-container">
    <v-container>
      <v-card>
      <v-breadcrumbs :items="[
        { text: 'Solutions', exact: true, to: { name: 'solutions' } },
        { text: 'Step Challenges', disabled: true },
      ]" />
      <div :class="` ${$vuetify.breakpoint.xs?'':''}`">
        <h1>Step Challenges</h1>
        <p>
          Build and strengthen inter-team connections within your organization. Host team challenges to promote your divisions, facilities or country departments to work together towards a common goal.
        </p>
      </div>

    <h2 class="title">Features for engaging corporate challenges</h2>
    <div class="mb-8">
      <v-row>
        <v-col cols="12" md="4">
          <v-sheet elevation="4">
            <v-img src="https://sodisp.imgix.net/content/fitness-phone.jpg?w=600" style="max-width:100%;" />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="8">
          <h3 class="subtitle-2">Step Challenges</h3>
          <p>
            Create your next step challenge on CorporateFitness.app. 
            The integrated step tracker in our Android and iPhone app can automatically track and sync the total daily steps. We use the AI powered algorithms that are already included in your phone to provide as accurate as possible step counts. This ensures there is no impact on battery life and enables the most accurate results.
          </p>
          <p>
            Did you know you can combine different challenge types in the same event? This allows you to create challenges which are engaging to all your employees, whether they are into running, walking, or yoga. We can even convert different activities to create inclusive leaderboards where every step and every dorp of sweat counts equally!
          </p>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12" md="12">
          <h3 class="subtitle-2">Automatic Step Tracking using Apple Watch or our app</h3>
          <p>
            Use the integrated step tracking in our app to track your daily steps and automatically sync them multiple times per day for real-time leaderboard updates.
          </p>
          <v-row>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1" class="text-center align-center pa-4">
                <v-icon size="50" color="grey">fab fa-apple</v-icon>
                <h3 class="my-4">iPhone</h3>
                <p>Use our app on your iPhone phone to track your daily steps.</p>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1" class="text-center align-center pa-4" >
                <v-icon size="50" color="grey">fab fa-android</v-icon>
                <h3 class="my-4">Android</h3>
                <p>Use our app on your Android phone to track your daily steps.</p>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1" class="text-center align-center pa-4" >
                <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
                <h3 class="my-4">Apple Watch</h3>
                <p>Auto-sync steps and activities from your Apple Watch.</p>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <h3 class="subtitle-2">Three engaging step challenge types</h3>
          <p>
            Choose between one of our engaging step challenge types to keep your team moving! Can't choose which is best for your team? No worries, you can combine multiple challenge modes at the same time!
          </p>
          <v-row>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1"  class="text-center align-center pa-4">
                <v-icon size="50" color="grey">fa-shoe-prints</v-icon>
                <h3 class="my-4">Total Steps</h3>
                <p>Ranking is based on the total number of steps tracked during the challenge.</p>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1"  class="text-center align-center pa-4" >
                <v-icon size="50" color="grey">fa-calendar-day</v-icon>
                <h3 class="my-4">Daily Target</h3>
                <p>Set a daily step target and see who can meet this target on most days.</p>
              </v-sheet>
            </v-col>
            <v-col cols="6" md="4">
              <v-sheet :elevation="1"  class="text-center align-center pa-4" >
                <v-icon size="50" color="grey">fa-calendar-week</v-icon>
                <h3 class="my-4">Weekly Target</h3>
                <p>Use a weekly step target to recognize consistent activity throughout the week.</p>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>      
    </div>
    
    <div class="secondary white--text py-6 py-md-12 mb-8">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <div class="my-6">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="subtitle">Download now!</h1>
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
      </v-row>

    </div>

    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-watch-fitness</v-icon></v-card-text>
            <v-card-title class="headline">Integrated Step Tracker</v-card-title>
            <v-card-text>
              Use your iPhone, Android, or Apple Watch to automatically track our daily steps on the background. 
              <br/>
              We automatically sync your progress to make every step count!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-user-headset</v-icon></v-card-text>
            <v-card-title class="headline">Full-Service support available</v-card-title>
            <v-card-text>
              Choose between our self-service or full-service packages to select the best option for your organization. 
              With the full-service package we take care of the initial setup and support so you can keep focussed on running your business!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-medal</v-icon></v-card-text>
            <v-card-title class="headline">Everyone wins!</v-card-title>
            <v-card-text>
              We offer various challenge types to promote physical and mental wellbeing within teams and organizations.
              Award Badges to keep your employees motivated and active throughout the entire challenge!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-shield-check</v-icon></v-card-text>
            <v-card-title class="headline">Private and secure</v-card-title>
            <v-card-text>
              We understand that health and fitness data of employees needs to be carefully protected. We have various options to configure which devices and options you want to offer to your employees.
            </v-card-text>
          </v-card>
        </v-col>
        
      </v-row>
    </div>

    <div class="grey lighten-4">
    <v-container class="mb-8">
      <v-row>
        <v-col class="pb-8">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <SectionIntegrations />

          <v-btn large block color="primary" class="mt-8" to="/features">Explore All Features</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
    
    
    <div id="page-customers" class="mt-0 white--text mx-n5 mb-8">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              Our platform is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>

          </v-col>
        </v-row>
      </div>
    </div>

    <div class="">
      <h2 class="subtitle">Solutions for Connected Fitness Challenges</h2>
      <v-list elevation="2" class="my-8 py-0">
        <v-list-item v-for="(item, idx) in navData['solutions-menu-cofi'].filter(x => x.highlight)" :key="idx" :to="item.to" >
          <v-list-item-icon>
            <v-icon size="25">fadl fa {{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-icon size="25">fal fa-chevron-circle-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

    </div>    
      </v-card>
    </v-container>
  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import navData from '@/data/nav.json'
import providers from '@/util/oauthProviders.js'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      navData: navData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
  .background-container { background-image: url(https://sodisp.imgix.net/content/running-shoes-stairs.jpg); }
</style>